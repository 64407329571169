<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="700"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                <v-card 
                    flat
                >
                    <v-card-text>
                        <v-form ref="form" lazy-validation>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Орган_принявший_акт") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <div class="icon-click-row-group icrg-align-center">
                                        <div @click="onEnterpriseIdSelect('EnterpriseId')">
                                            <v-icon small>
                                                fas fa-edit
                                            </v-icon>
                                        </div>
                                        <div class="onlyReadData" v-if="getEnterpriseName(approval.EnterpriseId)">
                                            <v-enterprise-chip
                                                v-if="getEnterpriseName(approval.EnterpriseId)"
                                                :name="getEnterpriseName(approval.EnterpriseId)"
                                            />
                                        </div>
                                        <div class="chipAreaNoData" v-else></div>
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Номер_регистрации") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-text-field
                                        v-model="approval.Number"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        :rules="requiredRule"
                                    >
                                    </v-text-field> 
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Дата_регистрации") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-menu 
                                        v-model="DateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="ApprovalDate | formattedDate"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on" 
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                class="datepick-input"
                                                clearable
                                                :rules="requiredRule"
                                            >
                                            </v-text-field>
                                        </template>

                                        <v-date-picker 
                                            v-model="ApprovalDate"
                                            @input="DateMenu = false"
                                            color="teal"
                                            :first-day-of-week="1"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                        </v-form>

                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    v-if="isValid"
                    @click="ok"
                >
                    {{ $t("Сохранить") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>

import sys from '@/services/system';
import { mapGetters } from 'vuex';

export default {
    name: "ApprovalActDlg",
    props: {
        contractors: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            title: "Информация_о_принятии_акта",
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            visible: false,
            resolve: null,
            reject: null,

            DateMenu: false,
            
            approval: null
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    computed: {
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
        }),
        ApprovalDate: {
            get: function() {
                if (this.approval.Date)
                    return this.$moment(this.approval.Date).format('YYYY-MM-DD');
                
                return this.approval.Date;
            },
            set: function(v) {
                this.approval.Date = `/Date(${Date.parse(v)})/`;
            }
        },
        isValid() {
            return this.approval.EnterpriseId && this.approval.Number && this.approval.Date;
        }
    },
    methods: {
        initNewEECBApprovalData() {
            return {
                __type: "EECBApprovalData:#Avrora.Objects.Modules.Docflow.DocflowObjects",
                // Идентификатор органа, принявшего акт
                EnterpriseId: null,
                // Номер регистрации
                Number: null,
                // Дата регистрации
                Date: null
            }
        },
        async open(approval) {
            if (approval)
                this.approval = approval;
            else
                this.approval = this.initNewEECBApprovalData();

            this.visible = true;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        getEnterpriseName(enterpriseId) {
            let contractor = this.contractors.find(x => x[0] === enterpriseId);
            return contractor ? contractor[1] : null;
        },
        async onEnterpriseIdSelect(prop) {
            try
            {
                this.visible = true;

                let selectMemberParams =  {
                    title: "Выбор_организации",
                    includeOuterEnterprises: true,
                    multiple: false,
                    selected: this.approval[prop] ? [ this.approval[prop] ] : []
                };

                let selected = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);

                this.approval[prop] = selected.enterprise;
            }
            catch (ex)
            {                
                console.log(ex);
            }
            finally
            {
                this.visible = true;
            }
        },
        ok() {
            this.visible = false;
            this.DateMenu = false;
            this.resolve(this.approval);
        },
        cancel() {
            this.visible = false;
            this.DateMenu = false;
            this.reject({ message: `Действие_отменено` });
        },
        validateFormMethod(){
            this.$refs?.form?.validate();    
        }
    },
    updated: function () {
        this.validateFormMethod();
    }
}
</script>