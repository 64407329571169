<template>
    <div>
        <v-dialog
            v-if="visible"
            v-model="notHidden"
            transition="dialog-top-transition"
            scrollable
            persistent
            max-width="1000"
        >
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text
                    v-if="loading"
                >
                    <v-col
                        class="text-subtitle-1 text-center"
                        cols="12"
                    >
                        {{ $t("Загрузка...") }}
                    </v-col>

                    <v-col cols="12">
                        <v-progress-linear
                            color="#a94442"
                            indeterminate
                            rounded
                            height="6"
                        ></v-progress-linear>
                    </v-col>

                </v-card-text>
                <v-card-text 
                    v-else
                    class="wrapperFormModal"
                >
                    <v-tabs
                        v-model="tab"
                        fixed-tabs
                        background-color="#e3e3ee"
                        class="tab-selector-panel"
                        slider-size="3"
                        mobile-breakpoint="0"                    
                    >
                        <v-tabs-slider color="#E43958"></v-tabs-slider>

                        <v-tab>
                            {{ $t("Сведения") }}
                        </v-tab>

                        <v-tab>
                            {{ $t("Принятие_акта") }}
                        </v-tab>

                        <v-tab>
                            {{ $t("Публикация_акта") }}
                        </v-tab>

                    </v-tabs>

                    <v-tabs-items v-model="tab" class="tab-content-panel">
                        <v-tab-item>

                            <v-card flat>
                                <v-card-text>

                                    <v-form ref="form" lazy-validation>
                                    
                                        <div class="form-box-title">{{ $t("Государственная_регистрация") }}</div>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label f-label-more-rows">
                                                    {{ $t("Предмет_регулирования") }}
                                                    <span>({{$t("каз._яз.")}})</span>
                                                </label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <v-text-field
                                                    :value="eecbMetaData.Title['kk-KZ']"
                                                    @input="$store.commit('dialogs/govermentRegistration/UPDATE_EECB_META_DATA', { property: 'Title.kk-KZ', value: $event })"
                                                    hide-details
                                                    outlined
                                                    dense
                                                    required
                                                    :rules="requiredRule"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label f-label-more-rows">
                                                    {{ $t("Предмет_регулирования") }}
                                                    <span>({{$t("рус._яз.")}})</span>
                                                </label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <v-text-field
                                                    :value="eecbMetaData.Title['ru-RU']"
                                                    @input="$store.commit('dialogs/govermentRegistration/UPDATE_EECB_META_DATA', { property: 'Title.ru-RU', value: $event })"
                                                    hide-details
                                                    outlined
                                                    dense
                                                    required
                                                    :rules="requiredRule"
                                                >
                                                </v-text-field> 
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Гос_орган-разработчик") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="icon-click-row-group icrg-align-center">
                                                    <div @click="onEnterpriseIdSelect('DeveloperEnterpriseId')">
                                                        <v-icon small>
                                                            fas fa-edit
                                                        </v-icon>
                                                    </div>
                                                    <div class="onlyReadData" v-if="getEnterpriseName(eecbMetaData.DeveloperEnterpriseId)">
                                                        <v-enterprise-chip
                                                            v-if="getEnterpriseName(eecbMetaData.DeveloperEnterpriseId)"
                                                            :name="getEnterpriseName(eecbMetaData.DeveloperEnterpriseId)"
                                                        />
                                                    </div>
                                                    <div class="chipAreaNoData" v-else></div>
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Место_принятия") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <v-autocomplete
                                                    :value="eecbMetaData.ApprovalPlace"
                                                    @input="$store.commit('dialogs/govermentRegistration/UPDATE_EECB_META_DATA', { property: 'ApprovalPlace', value: $event })"
                                                    :items="regions"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    append-icon="fas fa-chevron-down"
                                                    :rules="objectIdRule"
                                                >
                                                </v-autocomplete> 
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Регион_действия") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <v-autocomplete
                                                    :value="eecbMetaData.Region"
                                                    @input="$store.commit('dialogs/govermentRegistration/UPDATE_EECB_META_DATA', { property: 'Region', value: $event })"
                                                    :items="regions"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    append-icon="fas fa-chevron-down"
                                                    :rules="objectIdRule"
                                                >
                                                </v-autocomplete> 
                                            </v-col>
                                        </v-row>

                                        <!-- Орган_гос_регистрации -->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Орган_гос_регистрации") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="icon-click-row-group icrg-align-center">
                                                    <div @click="onEnterpriseIdSelect('JudiciaryAuthorityId')">
                                                        <v-icon small>
                                                            fas fa-edit
                                                        </v-icon>
                                                    </div>
                                                    <div class="onlyReadData">
                                                        <v-enterprise-chip
                                                            v-if="getEnterpriseName(eecbMetaData.JudiciaryAuthorityId)"
                                                            :name="getEnterpriseName(eecbMetaData.JudiciaryAuthorityId)"
                                                        />
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Номер_гос_регистрации") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <v-text-field
                                                    :value="eecbMetaData.JudiciaryNumber"
                                                    @input="$store.commit('dialogs/govermentRegistration/UPDATE_EECB_META_DATA', { property: 'JudiciaryNumber', value: $event })"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                >
                                                </v-text-field> 
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Дата_гос_регистрации") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <v-menu 
                                                    v-model="judiciaryDateMenu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            :value="JudiciaryDate | formattedDate"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on" 
                                                            hide-details
                                                            required
                                                            outlined
                                                            dense
                                                            class="datepick-input"
                                                            clearable
                                                        >
                                                        </v-text-field>
                                                    </template>

                                                    <v-date-picker 
                                                        v-model="JudiciaryDate"
                                                        @input="judiciaryDateMenu = false"
                                                        color="teal"
                                                        :first-day-of-week="1"
                                                    >
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>

                                    </v-form>
                                    
                                </v-card-text>
                            </v-card>

                        </v-tab-item>

                        <v-tab-item>

                            <!-- Кнопка Добавить -->
                            <div class="create-oth-incd-before-card-first more-per-lab-wrap">
                                <v-chip
                                    class="person-label immutable-text"
                                    small
                                    label
                                    color="orange lighten-3"
                                    @click="onApprovalDlg()"
                                >
                                    <v-icon color="#494F59">fas fa-plus</v-icon>
                                    <div>{{ $t("Добавить_информацию_о_принятии_акта") }}</div>
                                </v-chip>
                            </div>

                            <v-card 
                                class="cloud-file-list-box"
                                flat
                            >
                                <v-card-text>

                                    <!-- Для заголовка и поиска -->
                                    <div class="form-box-title">{{ $t("Информация_о_принятии_акта") }}</div>
                                    
                                    <!--Представление как в файлах-->
                                    <div class="cloud-items-container" v-if="eecbMetaData.ApprovalDataList.length > 0">
                                        <v-row 
                                            class="cfl-group-wrap"
                                            no-gutters
                                        >
                                            <v-col cols="12" sm="12" md="12" class="cfl-group-items">
                                                <v-row dense>
                                                    <v-col 
                                                        cols="12" sm="12" md="6"
                                                        class="cfl-item-wrap"
                                                        v-for="(item, index) in eecbMetaData.ApprovalDataList"
                                                        :key="index"
                                                    >
                                                        <div 
                                                            class="cfl-item"
                                                            
                                                        >
                                                            <div class="file-icon">
                                                                <i class="fas fa-file-alt dark-gray-color"></i>
                                                            </div>
                                                            <div class="cfl-info">

                                                                <div class="cfl-abs" v-tooltip.left-center="getEnterpriseName(item.EnterpriseId)+' № '+item.Number">
                                                                    <div class="cfl-filename">
                                                                        {{ getEnterpriseName(item.EnterpriseId) }} № {{ item.Number }}
                                                                    </div>
                                                                    <div>
                                                                        <div class="cfl-fileinfo">
                                                                            <span>
                                                                                {{ item.Date | formattedDate }}
                                                                            </span>                                          
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="cfl-actions">

                                                                <span 
                                                                    v-tooltip.left-center="$t('Редактировать')"
                                                                    @click="onApprovalDlg(item)"
                                                                >
                                                                    <i class="fas fa-edit dark-gray-color"></i>
                                                                </span>

                                                                <span 
                                                                    v-tooltip.left-center="$t('Удалить')"
                                                                    @click="onApprovalDelete(index)"
                                                                >
                                                                    <i class="fas fa-trash dark-gray-color"></i>
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </div> 
                                    <div class="cloud-items-container" v-else>
                                        <div class="cfl-placeholder">{{ $t('Не_указано') }}</div>
                                    </div>

                                </v-card-text>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item>

                            <div class="create-oth-incd-before-card-first more-per-lab-wrap">
                                <v-chip
                                    class="person-label immutable-text"
                                    small
                                    label
                                    color="orange lighten-3"
                                    @click="onPublicationDlg()"
                                >
                                    <v-icon color="#494F59">fas fa-plus</v-icon>
                                    <div>{{ $t("Добавить_информацию_о_публикации_акта") }}</div>
                                </v-chip>
                            </div>

                            <v-card 
                                class="cloud-file-list-box"
                                flat
                            >
                                <v-card-text>

                                    <div class="form-box-title">{{ $t("Информация_о_публикации_акта") }}</div>

                                    <!--Представление как в файлах-->
                                    <div class="cloud-items-container" v-if="eecbMetaData.PublicationList.length > 0">
                                        <v-row 
                                            class="cfl-group-wrap"
                                            no-gutters
                                        >
                                            <v-col cols="12" sm="12" md="12" class="cfl-group-items">
                                                <v-row dense>
                                                    <v-col 
                                                        cols="12" sm="12" md="6"
                                                        class="cfl-item-wrap"
                                                        v-for="(item, index) in eecbMetaData.PublicationList"
                                                        :key="index"
                                                    >
                                                        <div 
                                                            class="cfl-item"
                                                            
                                                        >
                                                            <div class="file-icon">
                                                                <i class="fas fa-file-alt dark-gray-color"></i>
                                                            </div>
                                                            <div class="cfl-info">

                                                                <div class="cfl-abs" v-tooltip.left-center="item.Source.Value">
                                                                    <div class="cfl-filename">
                                                                        {{ item.Source.Value }} № {{ item.IssueNumber }}
                                                                    </div>
                                                                    <div>
                                                                        <div class="cfl-fileinfo">
                                                                            <span>
                                                                                {{ item.Date | formattedDate }}
                                                                            </span>                                          
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="cfl-actions">

                                                                <span 
                                                                    v-tooltip.left-center="$t('Редактировать')"
                                                                    @click="onPublicationDlg(item)"
                                                                >
                                                                    <i class="fas fa-edit dark-gray-color"></i>
                                                                </span>

                                                                <span 
                                                                    v-tooltip.left-center="$t('Удалить')"
                                                                    @click="onPublicationDelete(index)"
                                                                >
                                                                    <i class="fas fa-trash dark-gray-color"></i>
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </div> 
                                    <div class="cloud-items-container" v-else>
                                        <div class="cfl-placeholder">{{ $t('Не_указано') }}</div>
                                    </div>

                                </v-card-text>
                            </v-card>
                        </v-tab-item>

                    </v-tabs-items>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                        color="cyan"
                        text
                        depressed
                        v-if="!loading && isValid"
                        @click="ok"
                    >
                        {{ $t("Отправить") }} 
                    </v-btn>

                    <v-btn
                        color="blue-grey"
                        text
                        depressed
                        @click="cancel"
                    >
                        {{ $t("Отмена") }}
                    </v-btn>

                </v-card-actions>

            </v-card>
            
        </v-dialog>

        <ApprovalActDlg ref="ApprovalAct" :contractors="contractors"/>
        <PublicationActDlg ref="PublicationAct" :sources="publishSources" />
    </div>
</template>

<script>
import sys from '@/services/system'
import { mapActions, mapGetters } from 'vuex'
import ApprovalActDlg from '@/components/dialogs/ApprovalActDlg'
import PublicationActDlg from '@/components/dialogs/PublicationActDlg'

export default {
    name: "GovermentRegistrationDlg",
    components: {
        ApprovalActDlg,
        PublicationActDlg
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    data () {
        return {
            title: "Отправить_в_ЭЭКБ_НПА",
            contractors: [],
            notHidden: true
        }
    },
    computed: {
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
            objectIdRule: 'getObjectIdRule'
        }),
        ...mapGetters('dialogs/govermentRegistration',
        { 
            visible: 'isVisible',
            loading: 'isLoading',
            eecbMetaData: 'getEECBMetaData',
            regions: 'getRegions',
            publishSources: 'getPublishSources',
            isNPA: 'isNPA'
        }),
        tab: {
            get: function() {
                return this.$store.getters['dialogs/govermentRegistration/getActiveTab'];
            },
            set: function(v) {
                this.$store.commit('dialogs/govermentRegistration/SET_ACTIVE_TAB', v);
            }
        },
        judiciaryDateMenu: {
            get: function() {
                return this.$store.getters['dialogs/govermentRegistration/isDateMenuOpen'];
            },
            set: function(v) {
                this.$store.commit('dialogs/govermentRegistration/SET_DATE_MENU', v);
            }
        },
        JudiciaryDate: {
            get: function() {
                if (this.eecbMetaData.JudiciaryDate)
                    return this.$moment(this.eecbMetaData.JudiciaryDate).format('YYYY-MM-DD');
                
                return this.eecbMetaData.JudiciaryDate;
            },
            set: function(v) {
                this.$store.commit('dialogs/govermentRegistration/UPDATE_EECB_META_DATA', { property: 'JudiciaryDate', value: `/Date(${Date.parse(v)})/` });
            }
        },
        isValid() {
            return  this.eecbMetaData?.Title?.['kk-KZ'] && //Предмет регулирования каз
                    this.eecbMetaData?.Title?.['ru-RU'] && //Предмет регулирования рус
                    this.eecbMetaData?.DeveloperEnterpriseId && //Гос орган разработчик
                    this.eecbMetaData?.ApprovalPlace?.id && //Место принятия
                    this.eecbMetaData?.Region?.id && //Регион действия
                    this.eecbMetaData?.ApprovalDataList?.length > 0; //есть хотя бы одно принятие акта
                    //this.eecbMetaData?.PublicationList?.length > 0; //есть хотя бы одна публикация акта
        }
    },
    methods: {
        ...mapActions('dialogs/govermentRegistration', [ 'ok', 'cancel' ]),
        onApprovalDelete(index) {
            let sourceCopy = JSON.parse(JSON.stringify(this.eecbMetaData.ApprovalDataList));
            sourceCopy.splice(index, 1);
            this.$store.commit('dialogs/govermentRegistration/UPDATE_EECB_META_DATA', { property: 'ApprovalDataList', value: sourceCopy });
        },
        onPublicationDelete(index) {
            let sourceCopy = JSON.parse(JSON.stringify(this.eecbMetaData.PublicationList));
            sourceCopy.splice(index, 1);
            this.$store.commit('dialogs/govermentRegistration/UPDATE_EECB_META_DATA', { property: 'PublicationList', value: sourceCopy });
        },
        async onApprovalDlg(sourceApproval = null) {
            try
            {
                this.notHidden = false;

                let newApproval = await this.$refs.ApprovalAct.open(sourceApproval);
                let sourceCopy = JSON.parse(JSON.stringify(this.eecbMetaData.ApprovalDataList));

                if (sourceApproval)
                    sourceCopy[sourceCopy.indexOf(sourceApproval)] = newApproval;
                else
                    sourceCopy.push(newApproval);

                this.$store.commit('dialogs/govermentRegistration/UPDATE_EECB_META_DATA', { property: 'ApprovalDataList', value: sourceCopy });
            }
            catch (ex)
            {
                console.log(ex);
            }
            finally
            {
                this.notHidden = true;
            }
        },
        async onPublicationDlg(sourcePublication = null) {
            try
            {
                this.notHidden = false;

                let newPublication = await this.$refs.PublicationAct.open(sourcePublication);
                let sourceCopy = JSON.parse(JSON.stringify(this.eecbMetaData.PublicationList));

                if (sourcePublication)
                    sourceCopy[sourceCopy.indexOf(sourcePublication)] = newPublication;
                else
                    sourceCopy.push(newPublication);

                this.$store.commit('dialogs/govermentRegistration/UPDATE_EECB_META_DATA', { property: 'PublicationList', value: sourceCopy });
            }
            catch (ex)
            {                
                console.log(ex);
            }
            finally
            {
                this.notHidden = true;
            }
        },
        async onEnterpriseIdSelect(prop) {
            try
            {
                this.notHidden = false;

                let selectMemberParams =  {
                    title: "Выбор_организации",
                    includeOuterEnterprises: true,
                    multiple: false,
                    selected: this.eecbMetaData[prop] ? [ this.eecbMetaData[prop] ] : [] 
                };
                let selected = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);
                this.$store.commit('dialogs/govermentRegistration/UPDATE_EECB_META_DATA', { property: prop, value: selected.enterprise });
            }
            catch (ex)
            {                
                console.log(ex);
            }
            finally
            {
                this.notHidden = true;
            }
        },
        getEnterpriseName(enterpriseId) {
            let contractor = this.contractors.find(x => x[0] === enterpriseId);
            return contractor ? contractor[1] : null;
        },
        validateFormMethod(){
            (async() => {
                while(typeof this.$refs.form === 'undefined')
                    await new Promise(resolve => setTimeout(resolve, 100));

                this.$refs.form.validate();
            })();    
        }
    },
    async created() {
        this.contractors = await this.$store.dispatch('references/getContractors');
    },
    updated: function () {
        this.validateFormMethod();
    }
}
</script>