<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="700"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                <v-card 
                    flat
                >
                    <v-card-text>
                        <v-form ref="form" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Источник_опубликования") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-select
                                        v-model="publication.Source"
                                        :items="sources"
                                        :item-text="item => $refLocale(item, $i18n.locale)"
                                        return-object
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        append-icon="fas fa-chevron-down"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        :rules="objectIdRule"
                                    >
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Номер_издания") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-text-field
                                        v-model="publication.IssueNumber"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        :rules="requiredRule"
                                    >
                                    </v-text-field> 
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Дата_опубликования") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-menu 
                                        v-model="DateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="PublicationDate | formattedDate"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on" 
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                class="datepick-input"
                                                clearable
                                                :rules="requiredRule"
                                            >
                                            </v-text-field>
                                        </template>

                                        <v-date-picker 
                                            v-model="PublicationDate"
                                            @input="DateMenu = false"
                                            color="teal"
                                            :first-day-of-week="1"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row> 
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    v-if="isValid"
                    @click="ok"
                >
                    {{ $t("Сохранить") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>

import sys from '@/services/system';
import { mapGetters } from 'vuex';

export default {
    name: "PublicationActDlg",
    props: {
        sources: {
            type: Array,
            default: () => []
        },
    },
    data () {
        return {
            title: "Информация_о_публикации_акта",
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            visible: false,
            resolve: null,
            reject: null,

            DateMenu: false,
            
            publication: null
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    computed: {
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
            objectIdRule: 'getObjectIdRule',
        }),
        PublicationDate: {
            get: function() {
                if (this.publication.Date)
                    return this.$moment(this.publication.Date).format('YYYY-MM-DD');
                
                return this.publication.Date;
            },
            set: function(v) {
                this.publication.Date = `/Date(${Date.parse(v)})/`;
            }
        },
        isValid() {
            return this.publication.Source.id && this.publication.Date;
        }
    },
    methods: {
        initNewEECBPublication() {
            return {
                __type: "EECBPublication:#Avrora.Objects.Modules.Docflow.DocflowObjects",
                // Источник опубликования
                Source: {
                    id: null,
                    Value: null
                },
                // Номер издания опубликования
                IssueNumber: null,
                // Дата опубликования
                Date: null
            }
        },
        async open(publication = null) {

            if (publication)
                this.publication = publication;
            else
                this.publication = this.initNewEECBPublication();

            this.visible = true;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        ok() {
            this.visible = false;
            this.resolve(this.publication);
        },
        cancel() {
            this.visible = false;
            this.reject({ message: `Действие_отменено` });
        },
        validateFormMethod(){
            this.$refs?.form?.validate();    
        }
    },
    updated: function () {
        this.validateFormMethod();
    }
}
</script>